import React from "react";
import "./App.css";
import logo from "../assets/logo_sello.png";
import {
  Box,
  Button,
  Typography,
  Modal,
  Grid,
  Paper,
  ButtonBase,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import wsp from "../assets/whatsapp.svg";
import kentron from "../assets/kentron-laser-co2-logo.svg";
import phone from "../assets/phone-volume-solid.svg";
import location from "../assets/map-location-dot-solid.svg"
import tikitok from "../assets/tiktok.svg"
import www from "../assets/earth-americas-solid.svg"
import factory from "../assets/industry-solid.svg"
import city from "../assets/city-solid.svg"
import x from "../assets/xmark-solid.svg"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "125vh",
  bgcolor: "background.paper",
  border: "2px",
  boxShadow: 24,
  p: 1,
  borderRadius: "20px",
  width: "max(60vw, 43vh )",
};

function App() {
  const [openAXMmodal, setOpenAXM] = React.useState(false);
  const [openBOGmodal, setOpenBOG] = React.useState(false);
  const [openCALImodal, setOpenCALI] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpen = (modalType) => {
    switch (modalType) {
      case "axm":
        setOpenAXM(true);
        break;
      case "bog":
        setOpenBOG(true);
        break;
      case "cali":
        setOpenCALI(true);
      // ... casos para otros tipos de modales
      default:
        break;
    }
  };
  const handleCloseModal = (modalType) => {
    switch (modalType) {
      case "axm":
        setOpenAXM(false);
        break;
      case "bog":
        setOpenBOG(false);
        break;
      case "cali":
        setOpenCALI(false);
      // ... otros casos para otros tipos de modales
      default:
        break;
    }};

  const handleClose = () => {
    setOpenAXM(false);
    setOpenBOG(false);
    setOpenCALI(false)
    // ... cierra otros modales si es necesario
  };

  return (
    <body>
      {/* -----------------ARMENIA---------------------- */}
      <Modal
        open={openAXMmodal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        
        <img onClick={() => handleCloseModal("axm")}src={x} alt="" className="cx" />
        <div className="todo">
          <Paper
            sx={{
              p: 1,
              
              margin: "2vh",
              top: "50%",
              boxShadow: "none",
              border: "none",
              direction:"column",
              alignContent: "center",
              justifyContent:"center"

            }}
          >
            <Grid
              container
              direction="row-reverse"
              alignItems="center"
              spacing={3}
              
            >
              <Grid item> 
              
                <div className="mapa">
                 
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d497.15910942987375!2d-75.67009806508177!3d4.544833400000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e38f559dcbef44b%3A0xc770e75bfeb07f4c!2sKentron%20Laser%20Armenia!5e0!3m2!1ses-419!2sco!4v1692285291714!5m2!1ses-419!2sco"
                    width="300"
                    height="200"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Grid>

              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      <Grid item>
                        <div className="logoC">
                        <img src={kentron} alt="" className="kLog" /></div>
                      </Grid>
                    </Typography>
                    <Typography variant="subtitle2"   gutterBottom>
                          
                   <div className="botonesC">
                    <div className="amxH"><h3>Kentron laser Punto de fabrica</h3> <br /></div>
                         <div className="dir"><a href="https://goo.gl/maps/DcSXqTtA4BfmdYGm9" target="_blank"
                          rel="noopener noreferrer"><button><img src={location} alt="" className="locationLog"/> Cra. 23 #3 - 24, Armenia, Quindío</button>
                      </a></div>
                      <div className="tel">
                        <a href="tel:6067453738">
                          <button><img src={phone} alt="" className="telLogo" />
                          6067453738</button>
                        </a>
                      </div>
                      <div className="numero">
                        <a
                          href="https://wa.me/message/AGZCCWLJ3CP3N1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button>
                            <img src={wsp} alt className="wspLogo" /> 315
                            3901115
                          </button>
                        </a>
                      </div>
                      </div>
                    </Typography>
                    <Typography variant="body2">
                      
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper> 
           </div>
        </Box>
      </Modal>
      {/* --------------------BOGOTA---------------- */}
      <Modal
        open={openBOGmodal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <img onClick={() => handleCloseModal("bog")} src={x} alt="" className="cx" />
        <div className="todo">
          <Paper
            sx={{
              p: 1,
              paddingLeft: "2vh",
              margin: "2vh",
              top: "50%",
              boxShadow: "none",
              border: "none",
            }}
          >
            <Grid
              container
              direction="row-reverse"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <div className="mapa">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1182.342507807911!2d-74.0942010347745!3d4.610074587955093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f998286584ad5%3A0xbaec510ca6628fc5!2sCentro%20gr%C3%A1fico%20Click!5e0!3m2!1ses!2sco!4v1692308522937!5m2!1ses!2sco"
                    width="300"
                    height="200"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Grid>

              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      <Grid item>
                        <div className="logoC">
                        <img src={kentron} alt="" className="kLog" /></div>
                      </Grid>
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      <div className="botonesC2">
                      <div className="bogH"><h3>Kentron laser Sede Bogota</h3> <br /></div>
                      <div className="dir2"><a href="https://goo.gl/maps/aY6hXzNG5vPhoD4PA" target="_blank"
                          rel="noopener noreferrer"><button><img src={location} alt="" className="locationLog"/> Centro comercial ClicK centro gráfico ricaurte <br />
                      Calle 11#28-45 local 104 <br /></button></a></div>
                      <div className="numero">
                        <a
                          href="https://wa.me/message/ESUOCWDKNWSFH1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button>
                            <img src={wsp} alt className="wspLogo" /> 318
                            7080502
                          </button>
                        </a>
                      </div>
                      </div>
                    </Typography>
                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            
          </Paper>
          </div>
        </Box>
      </Modal>
      {/* ----------------------CALI------------------------ */}
      <Modal
        open={openCALImodal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <img onClick={() => handleCloseModal("cali")} src={x} alt="" className="cx" />
        <div className="todo">
          <Paper
            sx={{
              p: 1,
              paddingLeft: "3vh",
              margin: "2vh",
              top: "50%",
              boxShadow: "none",
              border: "none",
            }}
          >
            <Grid
              container
              direction="row-reverse"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                
                <div className="mapa">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d248.9105297541104!2d-76.5298092!3d3.4545477!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e30a66894e63607%3A0xd9fd074aa9c22c62!2sCra.%203%20%2315-29%2C%20COMUNA%203%2C%20Cali%2C%20Valle%20del%20Cauca!5e0!3m2!1ses!2sco!4v1692712928325!5m2!1ses!2sco" width="300"
                    height="200"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </Grid>

              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      <Grid item>
                      <div className="logoC">
                        <img src={kentron} alt="" className="kLog" /> </div>
                      </Grid>
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                    <div className="botonesC2">
                      <h3>Kentron laser Sede Cali</h3> <br />
                      <div className="dir3"><a href="https://goo.gl/maps/sbonwBB1A1DP1enc7  " target="_blank"
                          rel="noopener noreferrer"><button><img src={location} alt="" className="locationLog"/> San Nicolás<br />
                      Carrera 3 #15-29 <br /></button></a></div>
                     <div className="numero">
                        <a
                          // href="https://wa.me/message/ESUOCWDKNWSFH1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button>
                            <img src={wsp} alt className="wspLogo" />  315 2339907
                          </button>
                        </a>
                      </div> </div>
                    </Typography>
                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          </div>
        </Box>
      </Modal>
      <div className="cardContainer">
        <div className="fondoMedio">
          {/* <img src={logo} alt="" className='logo' /> */}
          <div className="enlaces">
            <div>
              <a
                href="https://kentronlaser.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h1><img src={www} alt="" className="w"/>Web</h1>
              </a>
            </div>

            <div>
              <a href="" target="_blank" rel="noopener noreferrer">
                <a href="https://www.tiktok.com/@kentronlaser?lang=es" target="_blank"
                rel="noopener noreferrer"><h1><img src={tikitok} alt="" className="tt"/>TikTok</h1></a>
                
              </a>
            </div>

            <div className="axm">
              <h1 onClick={() => handleOpen("axm")}>
               <img src={factory} alt="" className="fP"/> Punto de fábrica Armenia
              </h1>
            </div>

            <div className="bog">
              <h1 onClick={() => handleOpen("bog")}>
                <img src={city} alt="" className="sedeLog"/>Sede Bogota</h1>
            </div>
            <div className="cali">
              <h1 onClick={()=> handleOpen("cali")}><img src={city} alt="" className="sedeLog" />Sede Cali</h1>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default App;
